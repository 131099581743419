<div class="margin-right-15">
  <ng-container [ngSwitch]="true">

    <ng-container *ngSwitchCase="inputConfig.type.includes('SEARCH')">
      <nz-form-item>
        <nz-form-label *ngIf="!inputConfig.isTitleHidden">
          {{inputConfig.title}}
        </nz-form-label>
        <nz-form-control>
          <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input [formControl]="inputControl" [value]="inputControl.value"
                   id="{{inputConfig.id}}"
                   placeholder="{{inputConfig.placeholder}}"/>
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <em *ngIf="inputControl.value && inputControl.value.length > 0"
               nz-icon
               nz-tooltip
               class="ant-input-clear-icon"
               nzTheme="fill"
               nzType="close-circle"
               (click)="onSearchClear()">
            </em>
            <em nz-icon nzType="search"></em>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </ng-container>

    <ng-container *ngSwitchCase="inputConfig.type === 'DATES_RANGE'">
      <nz-form-item>
        <nz-form-label [nzRequired]="inputConfig.isRequired" *ngIf="!inputConfig.isTitleHidden">
          {{inputConfig.title}}
        </nz-form-label>
        <nz-form-control [nzErrorTip]="!inputConfig.isValid ? inputConfig.errorTooltip : inputControl.getError('message')">
          <nz-range-picker [nzFormat]="'MM/dd/yyyy'" [formControl]="inputControl"
                           [nzAllowClear]="inputConfig.allowClear"
                           (nzOnOpenChange)="onDatesPickerOpenChange($event)"
                           [nzDisabledDate]="disabledDate" class="font-icon-color-darker">
          </nz-range-picker>
        </nz-form-control>
      </nz-form-item>
    </ng-container>
    <ng-container *ngSwitchCase="inputConfig.type.includes('SELECT')">
      <!--      SELECT_WITH_SWITCH-->
      <ng-container *ngIf="inputConfig.type === 'SELECT_WITH_SWITCH'">
        <nz-form-item>
          <nz-form-label [nzRequired]="inputConfig.isRequired" *ngIf="!inputConfig.isTitleHidden">
            {{inputConfig.title}}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="!inputConfig.isValid ? inputConfig.errorTooltip : inputControl.getError('message')">
            <nz-select style="width: 100%;"
                       class="app-input"
                       [formControl]="inputControl"
                       [nzPlaceHolder]="inputConfig.placeholder"
                       id="{{inputConfig.id}}"
                       nzShowSearch
                       [nzServerSearch]="inputConfig.isServerSearch"
                       [nzAllowClear]="inputConfig.allowClear"
                       [nzAutoClearSearchValue]="false"
                       [nzMode]="inputConfig.selectMode"
                       [nzMaxTagCount]="1"
                       nzShowArrow="false"
                       [nzSuffixIcon]="isSelectOpen ? arrowUp : arrowDown"
                       [nzDisabled]="inputConfig.isDisabled"
                       [nzLoading]="inputConfig.isLoading"
                       (ngModelChange)="onSelectWithSwitchChangeValue()"
                       (nzOpenChange)="onChangeSelectIconState()">
              <ng-container *ngIf="!inputConfig.isLoading">
                <nz-option *ngFor="let option of options; let i = index;"
                           nzCustomContent
                           [nzLabel]="inputConfig.possibleValues[i].displayName"
                           [nzDisabled]="inputConfig.possibleValues[i].isDisabled"
                           [nzValue]="option">
                <span nz-tooltip class="display-flex" [nzTooltipTitle]="inputConfig.possibleValues[i].displayName">
                  {{option}}
                  <span class="margin-left-small flex-1 display-flex justify-content-flex-end">
                    <nz-switch class="line-height-normal"
                               *ngIf="inputControl.value.includes(option)"
                               [(ngModel)]="inputConfig.possibleValues[i].switchState"
                               (ngModelChange)="onSelectWithSwitchChangeValue()"
                               [nzCheckedChildren]="inputConfig.possibleValues[i].switchOnText ? inputConfig.possibleValues[i].switchOnText : null"
                               [nzUnCheckedChildren]="inputConfig.possibleValues[i].switchOffText ? inputConfig.possibleValues[i].switchOffText : null"
                               nzSize="small"
                               (click)="$event.stopPropagation()"></nz-switch>
                  </span>
                </span>
                </nz-option>
              </ng-container>
              <nz-option *ngIf="inputConfig.isLoading" nzDisabled nzCustomContent>
                <em nz-icon nzType="loading" class="loading-icon"></em> Loading Data...
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
      <!--      SELECT & SELECT_WITH_SORT-->
      <ng-container *ngIf="inputConfig.type === 'SELECT' || inputConfig.type === 'SELECT_WITH_SORT'">
        <nz-form-item>
          <nz-form-label [nzRequired]="inputConfig.isRequired" *ngIf="!inputConfig.isTitleHidden">
            {{inputConfig.title}}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="!inputConfig.isValid ? inputConfig.errorTooltip : inputControl.getError('message')">
            <nz-input-group [ngClass]="{'select-and-sort': inputConfig.type === 'SELECT_WITH_SORT'}"
                            [nzAddOnAfter]="inputConfig.type === 'SELECT_WITH_SORT' ? sortBy : null">
              <nz-select style="width: 100%;"
                        class="app-input"
                        nz-tooltip
                        [nzTooltipTitle]="inputConfig.tooltip"
                        [formControl]="inputControl"
                        [nzPlaceHolder]="inputConfig.placeholder"
                        id="{{inputConfig.id}}"
                        nzShowSearch
                        [nzServerSearch]="inputConfig.isServerSearch"
                        [nzAllowClear]="inputConfig.allowClear"
                        [nzAutoClearSearchValue]="false"
                        [nzMode]="inputConfig.selectMode"
                        [nzMaxTagCount]="1"
                        nzShowArrow="false"
                        [nzSuffixIcon]="isSelectOpen ? arrowUp : arrowDown"
                        [nzDisabled]="inputConfig.isDisabled"
                        [nzLoading]="inputConfig.isLoading"
                        (ngModelChange)="onSelectChangeValue($event)"
                        (nzOnSearch)="onSearch($event)"
                        (nzOpenChange)="onChangeSelectIconState()">
                <ng-container *ngIf="!inputConfig.isLoading">
                  <nz-option *ngFor="let option of options; let i = index;"
                            nzCustomContent
                            [nzLabel]="inputConfig.possibleValues[i].displayName"
                            [nzDisabled]="inputConfig.possibleValues[i].isDisabled"
                            [nzValue]="option">
                  <span nz-tooltip
                        [nzTooltipTitle]="inputConfig.possibleValues[i].displayName + (inputConfig.showValueInLabel ? ' (id: ' + inputConfig.possibleValues[i].value + ')' : '')">
                    {{option}} {{inputConfig.showValueInLabel ? ' (id: ' + inputConfig.possibleValues[i].value + ')' : ''}}
                  </span>
                  </nz-option>
                </ng-container>
                <nz-option *ngIf="inputConfig.isLoading" nzDisabled nzCustomContent>
                  <em nz-icon nzType="loading" class="loading-icon"></em> Loading Data...
                </nz-option>
              </nz-select>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <ng-container *ngIf="inputConfig.type === 'SELECT_WITH_GROUPS'">
        <nz-form-item>
          <nz-form-label *ngIf="!inputConfig.isTitleHidden" [nzRequired]="inputConfig.isRequired">
            {{inputConfig.title}}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="!inputConfig.isValid ? inputConfig.errorTooltip : inputControl.getError('message')">
              <nz-select (ngModelChange)="onSelectChangeValue($event)"
                         (nzOnSearch)="onSearch($event)"
                         (nzOpenChange)="onChangeSelectIconState()"
                         [formControl]="inputControl"
                         [nzAllowClear]="inputConfig.allowClear"
                         [nzAutoClearSearchValue]="false"
                         [nzDisabled]="inputConfig.isDisabled"
                         [nzLoading]="inputConfig.isLoading"
                         [nzMaxTagCount]="1"
                         [nzMode]="inputConfig.selectMode"
                         [nzPlaceHolder]="inputConfig.placeholder"
                         [nzServerSearch]="inputConfig.isServerSearch"
                         [nzSuffixIcon]="isSelectOpen ? arrowUp : arrowDown"
                         [nzTooltipTitle]="inputConfig.tooltip"
                         class="app-input"
                         id="{{inputConfig.id}}"
                         nz-tooltip
                         nzShowArrow="false"
                         nzShowSearch
                         style="width: 100%;">
                <ng-container *ngIf="!inputConfig.isLoading">
                  <nz-option-group *ngFor="let group of groups; let x = index;" [nzLabel]="group.groupDisplayName">
                      <nz-option *ngFor="let option of group.possibleValues; let i = index;"
                                 [nzDisabled]="option.isDisabled"
                                 [nzLabel]="option.displayName"
                                 [nzValue]="option.value"
                                 nzCustomContent>
                            <span [nzTooltipTitle]="option.displayName + (inputConfig.showValueInLabel ? ' (id: ' + option.value + ')' : '')"
                                  nz-tooltip>
                              {{option.displayName}} {{inputConfig.showValueInLabel ? ' (id: ' + option.value + ')' : ''}}
                            </span>
                      </nz-option>
                  </nz-option-group>
                </ng-container>
                <nz-option-group *ngIf="inputConfig.isLoading" nzCustomContent nzDisabled>
                  <em class="loading-icon" nz-icon nzType="loading"></em> Loading Data...
                </nz-option-group>
              </nz-select>
          </nz-form-control>
        </nz-form-item>
      </ng-container>


    </ng-container>
  </ng-container>
</div>

<ng-template #arrowUp>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-open"></fa-icon>
</ng-template>
<ng-template #arrowDown>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-close"></fa-icon>
</ng-template>
<ng-template #sortBy>
  <div class="cursor-pointer" (click)="sortByToggle()">
    <fa-icon *ngIf="!sortByAsc" [icon]="'sort-amount-down'"></fa-icon>
    <fa-icon *ngIf="sortByAsc" [icon]="'sort-amount-up'"></fa-icon>
  </div>
</ng-template>

