import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AppTable,
  AppTableColumn,
  AuthPermissions,
  CtrlUser,
  ModalActionType,
  ModalButton,
  SentinelResponse,
  SentinelRule,
  SentinelSubscription,
  SentinelSubscriptionResponse,
  ThinPublisher,
} from '../../../_models/models';
import {
  addAlertToPublisherModalButtons,
  alertsTable,
  authPermissions,
  EditAlertRow,
  editAlertsTableColumns,
  EXTERNAL_ALERTS_SENTINEL_GROUP,
  INTERNAL_ALERTS_SENTINEL_GROUP,
  PublisherDescription,
  SentinelRuleGroup
} from '../../../_services/alerts.config';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AddPublisherToAlertComponent } from './add-publisher-to-alert/add-publisher-to-alert.component';
import { AlertsService } from '../../../_services/alerts.service';
import { catchError, filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import { AuthenticationService } from '../../../../auth/_services/authentication.service';
import { FormHelper } from '../../../_common/form.helper';
import { SupplyPublisherService } from '../../../../features/supply/publishers/_services/publisher.service';

@Component({
  selector: 'app-edit-alert',
  templateUrl: './edit-alert.component.html',
  styleUrls: ['./edit-alert.component.less']
})
export class EditAlertComponent implements OnInit, OnDestroy {

  @ViewChild(AddPublisherToAlertComponent) addPublisher: AddPublisherToAlertComponent;
  alertsTableColumns: AppTableColumn[] = editAlertsTableColumns;
  alertsTableData: EditAlertRow[] = [];
  addPublisherForm: UntypedFormGroup;
  authUser: AuthPermissions = authPermissions;
  currentAlert: Pick<SentinelRule, 'id' | 'name' >;
  isLoading = false;
  isModalVisible = false;
  modalButtons: ModalButton[] = addAlertToPublisherModalButtons;
  publishersAlertsTable: AppTable = alertsTable;
  successfulModalSubmit = false;
  user: CtrlUser = null;
  userRuleGroup: SentinelRuleGroup;
  currentAlertActiveSubscription: SentinelSubscriptionResponse = null;

  private unsubscribe$ = new Subject<void>();




  constructor(private fb: UntypedFormBuilder,
    private alertsService: AlertsService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private fh: FormHelper,
    private publishersService: SupplyPublisherService) { }



  ngOnInit(): void {
    this.currentAlert = {name: '', id: 0};
    this.initAddPublisherForm();
    this.alertsService.getSentinelKongApiKey()
      .pipe(
        filter(apiKeyResponse => apiKeyResponse.length > 0),
        switchMap(() => this.route.params.pipe(
          map(params => +params.id)
        )),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(alertIdParam => {
        this.initCurrentUser();
        this.initCurrentAlert(alertIdParam);
      });
  }

  initCurrentAlert(alertId: number): void {
    this.isLoading = true;
    this.alertsService.activeSubscriptions$
      .pipe(
        map(subscriptions => subscriptions.filter(subscription => subscription.rules.id === this.currentAlert.id)[0]),
        takeUntil(this.unsubscribe$)
      ).subscribe(res => {
        this.currentAlertActiveSubscription = res;
      });
    this.alertsService.getSentinelRuleById(alertId)
      .pipe(
        catchError(() => {
          this.alertsService.handleErrors('Could not load alert');
          this.isLoading = false;
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$),
      ).subscribe((res) => {
        if (!res.data || res.data.groupName !== this.userRuleGroup) {
          this.alertsService.handleErrors('Alert was not found');
          this.isLoading = false;
          return;
        }
        this.currentAlert.id = res.data.id;
        this.currentAlert.name = res.data.name;
        this.initActiveSubscriptions();
      });
  }


  initCurrentUser(): void {
    this.user = this.auth.currentUserValue;
    this.userRuleGroup = this.user.type === 'INTERNAL' ? INTERNAL_ALERTS_SENTINEL_GROUP : EXTERNAL_ALERTS_SENTINEL_GROUP;
  }

  initAddPublisherForm(): void {
    this.addPublisherForm = this.fb.group({
      publisherId: this.fb.control('', [Validators.required, this.publisherExistsValidator()]),
    });
  }

  initActiveSubscriptions(): void {
    if (!this.currentAlertActiveSubscription) {
      this.getActiveSubscription()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(res => {
          this.alertsTableData = res;
        });
    } else {
      const getPublishersQuery = this.alertsService.buildGetPublishersNamesQuery(this.currentAlertActiveSubscription);
      this.publishersService.getFilteredPublishers(getPublishersQuery)
        .pipe(
          map(res => this.mapSubscriptionsToRows(this.currentAlertActiveSubscription, res.data)),
          finalize(() => this.isLoading = false),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(res => {
          this.alertsTableData = res;
        });
    }
  }

  getActiveSubscription(): Observable<EditAlertRow[]> {
    return this.alertsService.getActiveSubscriptions(this.user.id)
      .pipe(
        map(subscriptions => subscriptions.data.filter(
          subscription => subscription.rules.id === this.currentAlert.id
        )),
        filter(subscriptions => subscriptions?.length > 0),
        map(subscriptions => subscriptions[0]),
        tap( res => this.currentAlertActiveSubscription = res),
        switchMap(subscriptions => this.publishersService
          .getFilteredPublishers(this.alertsService.buildGetPublishersNamesQuery(subscriptions)
          )
          .pipe(
            map(res => this.mapSubscriptionsToRows(subscriptions, res.data)),
            catchError(() => this.alertsService.handleErrors('Failed to load publishers')))
        ),
        finalize(() => {
          this.isLoading = false;
        }),
        catchError(() => this.alertsService.handleErrors('Failed to load active subscriptions'))
      );
  }

  onModalEvent(modalButtonClickEvent: ModalActionType): void {
    this.fh.markFormGroupDirtyAndUpdateValidity(this.addPublisherForm);
    switch (modalButtonClickEvent) {
      case 'APPROVE':
        this.addPublisher.onSubmit();
        if (!this.successfulModalSubmit) {
          return;
        }
        this.addPublisherToTable();
        break;
      case 'CANCEL':
        break;
    }
    this.addPublisherForm.controls['publisherId'].reset();
    this.toggleModalView();
  }

  toggleModalView(): void {
    this.isModalVisible = !this.isModalVisible;
  }

  isSuccessModalSubmit(submitValue: boolean): void {
    this.successfulModalSubmit = submitValue;
  }

  onAlertRowAction(rowEvent: {row: EditAlertRow; action: string}): void {
    switch (rowEvent.action) {
      case 'alertToggleAction':
        this.toggleAlert(rowEvent.row);
        return;
      case 'alertDeleteAction':
        this.deleteAlert(rowEvent.row)
          .subscribe(res => {
            this.alertsService.activeSubscriptions$.next(res);
            this.alertsTableData = this.alertsTableData
              .filter(alert => alert?.publisher.publisherId !== rowEvent.row?.publisher.publisherId);
          });
        return;
    }

  }
  activateAlert(publisher: PublisherDescription): void {
    this.getActivateAlertObservable(publisher.publisherId)
      .pipe(catchError(() => this.alertsService.handleErrors('Failed to subscribe')))
      .subscribe(res => {
        const newTableEntry: EditAlertRow = this.createEditRowEntry(res.data, publisher);
        const updatedSubscriptions = this.updateActiveSubscriptions(res.data);
        this.alertsService.activeSubscriptions$.next(updatedSubscriptions);
        this.alertsTableData = [...this.alertsTableData, newTableEntry];
      });
  }

  toggleAlert(alert: EditAlertRow): void {
    if (alert.status === 'ACTIVE') {
      this.deleteAlert(alert).subscribe(() => {
        const alertToUpdateIndex = this.alertsTableData.findIndex(row => row.publisher.publisherId === alert.publisher.publisherId);
        this.alertsTableData[alertToUpdateIndex].status = 'INACTIVE';
      });
      return;
    }
    this.activateAlert({publisherId: alert.publisher.publisherId, publisherName: alert.publisher.publisherName});
  }


  deleteAlert(publisherAlert: EditAlertRow): Observable<SentinelSubscriptionResponse[]> {

    const filteredPublishersArray = this.currentAlertActiveSubscription
      ?.templateValue
      .values
      .publishers
      .filter(publisher => publisher !== publisherAlert.publisher.publisherId);

    if (filteredPublishersArray?.length > 0) {
      const query: SentinelSubscription = this.alertsService
        .buildSentinelQuery(publisherAlert.ruleId, this.user, filteredPublishersArray);
      return this.updateSubscriptionAfterDelete(query)
        .pipe(
          map(res => this.updateActiveSubscriptions(res))
        );
    } else {
      return this.alertsService.unsubscribeAlert(publisherAlert.subscriptionId)
        .pipe(
          catchError(() => this.alertsService.handleErrors('Failed to unsubscribe')),
          map(() => this.removeFromActiveSubscriptions(publisherAlert))
        );
    }
  }

  updateSubscriptionAfterDelete(query: SentinelSubscription) {
    return this.alertsService.updateSubscription(this.currentAlertActiveSubscription.id, query)
      .pipe(
        catchError(() => this.alertsService.handleErrors('Failed to unsubscribe')),
        map(subscriptionResponse => subscriptionResponse.data)
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  private addPublisherToTable(): void {
    this.activateAlert(this.addPublisher.selectedPublisher);
  }

  private publisherExistsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const publisherAlreadyInTable = this.alertsTableData
        ?.find(activeSubscription => activeSubscription.publisher.publisherId === control.value);
      return publisherAlreadyInTable ? {publisherAlreadyInTable: {value: control.value}} : null;
    };

  }


  private mapSubscriptionsToRows = (subscription: SentinelSubscriptionResponse, publishers: ThinPublisher[]): EditAlertRow[] => publishers
    .map(publisher => {
      const publisherDescription: PublisherDescription = {
        publisherId: publisher.publisherId,
        publisherName: publisher.publisherName
      };
      return this.createEditRowEntry(subscription, publisherDescription);
    });

  private createEditRowEntry = (subscription: SentinelSubscriptionResponse, publisher: PublisherDescription): EditAlertRow => ({
    status: 'ACTIVE',
    ruleId: subscription.rules.id,
    publisher: publisher,
    name: subscription.rules.name,
    description: subscription.rules.description,
    notificationName: subscription.rules.notificationName,
    subscriptionId: subscription.id
  });

  private removeFromActiveSubscriptions(alert: EditAlertRow): SentinelSubscriptionResponse[] {
    return this.alertsService.activeSubscriptions$.value
      .filter(sub => sub.id !== alert.subscriptionId);
  }


  private getActivateAlertObservable(publisherId: string): Observable<SentinelResponse<SentinelSubscriptionResponse>> {
    const publishersArray: string[] = this.getFilteredPublishersArray(publisherId);

    const sentinelQuery: SentinelSubscription = this.alertsService.buildSentinelQuery(this.currentAlert.id, this.user, publishersArray);

    if (publishersArray.length === 1) {
      return this.alertsService.subscribeAlert(sentinelQuery);
    }

    return this.alertsService.updateSubscription(this.currentAlertActiveSubscription.id, sentinelQuery);
  }

  private getFilteredPublishersArray(publisherId: string): string[] {
    const currentSubscriptionPublishers: string [] = this.currentAlertActiveSubscription?.templateValue.values.publishers || [];

    currentSubscriptionPublishers.push(publisherId);

    return [...new Set(currentSubscriptionPublishers)];
  }

  private updateActiveSubscriptions(subscriptionResponse: SentinelSubscriptionResponse): SentinelSubscriptionResponse[] {
    let subscriptionToUpdate: SentinelSubscriptionResponse[] = this.alertsService.activeSubscriptions$.value;

    const foundSubscriptionIndex: number = subscriptionToUpdate.findIndex(sub => sub.id === subscriptionResponse.id);

    if (foundSubscriptionIndex === -1) {
      subscriptionToUpdate = [subscriptionResponse];
    } else {
      subscriptionToUpdate[foundSubscriptionIndex].templateValue.values.publishers =
      subscriptionResponse.templateValue.values.publishers;
    }
    return subscriptionToUpdate;
  }
}
