import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {CurrentUser, CtrlUser} from '../../shared/_models/models';

type PermissionType = 'supply.*' | 'system.*';
type MainRoute = 'supply' | 'admin' | 'portal';

@Injectable({providedIn: 'root'})
export class AuthGuard  {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private nzNotificationService: NzNotificationService
  ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const mainPagesPermission = [
      this.isPermitted(route, 'supply', 'supply.*'),
      this.isPermitted(route, 'admin', 'system.*'),
      this.isPermitted(route, 'portal', 'supply.*', true),
    ];

    if (mainPagesPermission.some(p => p === false)) {
      return false;
    }

    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    const queryParams = (state.url && state.url !== '/') ? {returnUrl: state.url} : null;
    this.router.navigate(['/auth/login'], {queryParams});
    return false;
  }
  private isPermitted(
    route: ActivatedRouteSnapshot, checkMainRoute: MainRoute, permissionType: PermissionType, prevent = false
  ): boolean {
    if (route.routeConfig.path === checkMainRoute) {
      if (this.authenticationService.hasAccess(permissionType) === prevent) {
        this.nzNotificationService.warning('Not allowed', 'You do not have the required permissions to access this page.');
        this.router.navigate(['/']);
        return false;
      }
    }
    return true;
  }
}
