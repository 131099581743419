import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  console.log = () => {};
  enableProdMode();
}

Sentry.init({
  dsn: 'https://b743b3adbb874b718484c20bd09e35e8@o565565.ingest.sentry.io/6578732',
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['https://ctrl-japi.unruly.co/insights', 'https://ctrl-japi.unruly.co/api'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  environment: environment.envName,
  denyUrls: ['http://localhost:4200'],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.67,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
