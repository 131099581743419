export const environment = {
  production: false, // should be true for production
  googleTagContainerId: 'GTM-N3F2VDM',
  envName: 'int', // should be production for production
  ondemandUser: 'NO-USER',
  javaApiUrl: 'https://int1-ctrl-api.nexxen.com/api',
  javaInsightsUrl: 'https://int1-ctrl-api.nexxen.com/insights',
  spearadUrl: 'https://console.spearad.video/login',
  spearadIframeUrl: 'https://console.spearad.video',
  sentinelApi: 'https://dev-api.unruly.co/sentinel',
  exchangeDealsUrl: 'https://int1-rx-deals.nexxen.com/api',
  captcha: false, // should be true for production
  authServer: 'https://dev-api.unruly.co/realms/fusion-int1/protocol/openid-connect',
  zendeskUrl: 'https://unrulyssp-help.zendesk.com'
};
