import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { SanitizerType } from '../_models/models';

@Pipe({
  name: 'sanitizer'
})
export class SanitizerPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {
  }

  public transform(value: any, type: SanitizerType): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    if (value) {
      switch (type) {
        case 'html':
          return this.domSanitizer.bypassSecurityTrustHtml(value);
        case 'style':
          return this.domSanitizer.bypassSecurityTrustStyle(value);
        case 'script':
          return this.domSanitizer.bypassSecurityTrustScript(value);
        case 'url':
          return this.domSanitizer.bypassSecurityTrustUrl(value);
        case 'resourceUrl':
          return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
        default:
          throw new Error(`Invalid safe type specified: ${type}`);
      }
    }
  }
}
