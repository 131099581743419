import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EventService, AuthenticationService, VersionCheckService } from '../../_services';
import { debounce } from 'lodash';
import { environment } from '../../../environments/environment';
import * as Sentry from '@sentry/angular';
import {KeycloakToken} from '../_models/models';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private debouncedFunction = {
    unknown: null,
    unauthenticated: null
  };

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private auth: AuthenticationService,
    private notification: NzNotificationService,
    private eventService: EventService,
    private vcs: VersionCheckService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(err => {
      if (request.url.startsWith(`${environment.authServer}`)) {
        return throwError(err);
      }
      if (
        (request.url.startsWith(`${environment.javaApiUrl}`) || request.url.startsWith(`${environment.javaInsightsUrl}`)
          || request.url.startsWith(`${environment.exchangeDealsUrl}`))
          && err.status === 401 && this.auth.currentUserValue
      ) {
        this.sentryLogout();
        return this.handle401Error(request, next);
      }
      if (request.headers.get('validate-only')) {
        return next.handle(request);
      }
      const response = err.error;

      if (err.status === 500) {
        const isHideMessage = !!request.headers.get('no-error-message');
        const isShowErrorNotification = !request.headers.get('no-error-notification');
        if (isHideMessage) {
          console.clear();
        }
        const errTitle = err.statusText ? (err.statusText === 'OK' ? 'Unexpected error' : err.statusText) : 'Unexpected error';
        const errDetails = response.data && response.data[0].error ? response.data[0].error : 'Server error occurred';
        if (isShowErrorNotification) {
          this.notification.error(errTitle, errDetails);
        }
          // // probably in most cases no need to retry, so return empty observable
        // return of<HttpEvent<any>>();
        this.refreshIfNeeded();
      }

      if (err.status === 0) {
        const isHideMessage = !!request.headers.get('no-error-message');
        const isShowErrorNotification = !request.headers.get('no-error-notification');
        if (isHideMessage) {
          console.clear();
        }
        if (isShowErrorNotification) {
          this.debouncedError('unknown', 'Something went wrong', 'Connection error occurred.', 1000, isHideMessage);
        }
        throwError(response);

        if (request.headers.get('no-retry-request')) {
          return of<HttpEvent<any>>();
        } else if (request.headers.get('no-retry-request-with-error')) {
          return throwError(response);
        } else {
          return next.handle(request);
        }
      }

      // Error cases:
      if (response && response.data && response.data[0] && response.data[0].error) {

        if (response.data[0].error == 'Validation error') {
          const errorList = response.data[0].error_list;
          this.eventService.emitEvent('validation-failed', errorList);
          let html_errors_output = '';

          html_errors_output += '<ul>';
          for (const fieldError in errorList) {
            if (errorList[fieldError]) {
              html_errors_output += '<li>' + errorList[fieldError][0] + '</li>';
            }
          }
          html_errors_output += '</ul>';

          this.notification.error(response.data[0].error, html_errors_output);
        } else if (response.data[0].error == 'Error') {
          this.notification.error(response.data[0].error, response.data[0].message);
        } else if (response.data[0].message == 'Unauthenticated.') {
          this.debouncedError('unauthenticated', response.data[0].error, response.data[0].message, 1000);
        } else {
          this.notification.error(response.data[0].error, response.data[0].message);
        }

      }

      return next.handle(request);
    }));
  }

  debouncedError(id, title, message, timeout, isHideMessage = true) {
    if (this.debouncedFunction[id]) {
      this.debouncedFunction[id].cancel();
    }
    if (!isHideMessage) {
      this.debouncedFunction[id] = debounce(() => {
        this.notification.error(title, message);
      }, timeout);
      this.debouncedFunction[id]();
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    // Only the first unauthorized request will actually initiate the refresh process.
    // The rest (any other request initiated while the refresh is happening) will wait for the new token
    // through the returned refreshTokenSubject below.
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.auth.currentUserValue.refresh_token;
      if (token) {
        return this.auth.refreshAuth(token).pipe(
          switchMap((token: KeycloakToken) => {
            // Update the new token in AuthService
            this.auth.onUpdateAccessToken(token);
            // Send the new token to any other request waiting for it
            this.refreshTokenSubject.next(token.access_token);
            // Re-handle the first unauthorized request
            return next.handle(this.setBearerToken(request, token.access_token));
          }),
          catchError((err) => {
            this.auth.doLogout(true);
            setTimeout(() => {
              this.refreshIfNeeded();
            }, 1000);
            return throwError(err);
          }),
          finalize(() => {
            this.isRefreshing = false;
          }),
        );
      }
    }
    // Other requests will wait and re-handle themselves when the new token arrives
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.setBearerToken(request, token)))
    );
  }

  refreshIfNeeded() {
    if (this.vcs.isRefreshNeededValue) {
      this.vcs.refreshPage();
    }
  }

  private setBearerToken(request: HttpRequest<any>, token: string): HttpRequest<any>{
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  sentryLogout(): void {
    const transaction = Sentry.getCurrentHub()
      .getScope()
      .getTransaction();
    if (transaction) {
      const span = transaction.startChild({
        op: "logout",
        description: "401 error interceptor",
      });
      // span.updateWithContext()
      try {
        span.setStatus('Ok');
      } catch (err) {
        span.setStatus('UnknownError');
        throw err;
      } finally {
        span.finish();
      }
    }
  }

}
