import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { LineChartComponent, Color } from '@swimlane/ngx-charts';
import * as shape from 'd3-shape';
import { find, clamp } from 'lodash';
import {AppChartConfig, AppChartResult, AppChartSeries} from '../../_models/models';

@Component({
  selector: 'app-chart',
  templateUrl: './app-chart.component.html',
  styleUrls: ['./app-chart.component.less'],
})
export class AppChartComponent implements AfterViewInit {


  private _config: AppChartConfig = null;
  @Input()
  set config(config: AppChartConfig) {
    if (config && config.data?.length) {
      this.updateWrapperHeight();
    }
    if (config.type === 'line' && config.data?.length) {
      this.onePointLineChartsIndexesClass = '';
      this.endOfPartialLineChartIndexesClass = '';
      config.data.forEach((seriesConfig, idx) => {
        if (seriesConfig.series?.length === 1) {
          this.onePointLineChartsIndexesClass += ` single-point-series-${idx + 1}`;
        }
        if (seriesConfig.name.includes('partial') && seriesConfig.series?.length > 1) {
          if (!config.data[idx + 1]?.name.includes('partial-no-tooltip')) {
            const partialLastPointSeries = {
              name: `${config.title}-partial-no-tooltip`,
              series: seriesConfig.series.slice(-1),
              isVisibleInLegend: true,
              nameForChartTooltip: 'Last data point is not final',
              legendTooltipTitle: 'Data aggregation in progress and may change',
            };
            (<AppChartSeries[]>config.data).push(...[partialLastPointSeries, {...partialLastPointSeries, isVisibleInLegend: false}]);
            this.endOfPartialLineChartIndexesClass += ` single-point-series-${idx + 2} hollow-point-series-${idx + 3}`;
          }
        }
      });
    }
    if (config.type === 'line' && config.timeline !== undefined && this.lineChartEl) {
      this.refreshTimeline(this.lineChartEl);
    }
    if ((config.type === 'barHorizontal' || config.type === 'barVertical') && config.data?.length) {
      this.isBarChartAllValuesZero = (<AppChartResult[]>config.data).filter(item => item.value).length ? false : true;
    }
    if (config.type === 'gauge' && config.data?.length) {
      this.gaugeMax = 0;
      config.data.forEach(obj => this.gaugeMax = Math.max(obj.value, this.gaugeMax));
    }
    this._config = config;
    if (this.config.type === 'barHorizontal' && this.config.data?.length) {
      this.formatBarChartTextDataLabel();
    }
  }
  get config() {
    return this._config;
  }

  @ViewChild('wrapper') wrapper?: ElementRef<HTMLElement>;
  @ViewChild('lineChartEl') lineChartEl: LineChartComponent;

  width: number = 200;
  height: number = 100;
  DEFAULT_MIN_HEIGHT: number = 250;
  DEFAULT_MAX_HEIGHT: number = 300;
  DEFAULT_ASPECT_RATIO: number = 0.5;
  isLoading: boolean = false;
  shape = shape;
  gaugeMax: number = 100;
  onePointLineChartsIndexesClass: string = '';
  endOfPartialLineChartIndexesClass: string = '';
  public isBarChartAllValuesZero: boolean = false;

  @HostListener('window:resize', ['$event'])
  updateWrapperHeight() {
    const minHeight = this.config?.size?.minHeight || this.DEFAULT_MIN_HEIGHT;
    let maxHeight = this.config?.size?.maxHeight || this.DEFAULT_MAX_HEIGHT;
    const aspectRatio = this.config?.size?.aspectRatio || this.DEFAULT_ASPECT_RATIO;
    if (this.wrapper) {
      const rect = this.wrapper.nativeElement.getBoundingClientRect();
      this.width = rect.width;
      let height = this.width * aspectRatio;
      if (this.config.type === 'line' && this.config.timeline) {
        height += 70;
        maxHeight += 70;
      }
      this.height = clamp(height, minHeight, maxHeight);
    }
  }

  colorScheme: Color = {
    name: null,
    selectable: null,
    group: null,
    domain: [
      '#404C71',
    ],
  };


  constructor(private cdr: ChangeDetectorRef) {}


  ngAfterViewInit() {
    this.updateWrapperHeight();
    this.cdr.detectChanges();
  }

  xAxisFormat = (val) => {
    if (this.config.xAxis?.tickFormat) { return this.config.xAxis.tickFormat(val, this.config); }
    return val;
  }

  yAxisFormat = (val) => {
    if (this.config.yAxis?.tickFormat) { return this.config.yAxis.tickFormat(val, this.config); }
    return val;
  }

  getTooltipText(item) {
    if (this.config.type === 'barHorizontal') {
      return this.xAxisFormat(item.value);
    }
    return this.yAxisFormat(item.value);
  }

  showTooltipItem(item, model, itemIdx) {
    if (
      (item.series.includes('-partial') &&
      model[itemIdx - 1]?.series.includes('-current') &&
      item.value === model[itemIdx - 1]?.value) ||
      (item.series.includes('-no-tooltip'))
    ) {
      return false;
    }
    return true;
  }

  getNameForChartTooltip(seriesName) {
    const name = find(this.config.data, ['name', seriesName])?.['nameForChartTooltip'];
    return name || seriesName;
  }

  formatBarChartTextDataLabel() {
    setTimeout(() => {
      const nodes = document.querySelectorAll(`app-chart[id*="${this.config.key}"] .textDataLabel`);
      nodes.forEach((el: HTMLElement) => {
        const value = Number(el.innerHTML.trim().replace(/,/g, ''));
        if (!Number.isNaN(value)) {
          el.innerHTML = this.xAxisFormat(value);
        }
      });
    }, 0);
  }

  // If timeline is filtered and chart config has changed, timeline selection needs to be reset..
  // This is the only solution out there right now.
  refreshTimeline(chart: LineChartComponent) {
    chart.filteredDomain = null;
    // Chart HTML element
    const chartElement: HTMLElement = chart['chartElement'].nativeElement;
    // Two lines at first and end of the selection box should be disappeared
    chartElement.querySelector('rect.handle.handle--w')?.setAttribute('style', 'width:0px');
    chartElement.querySelector('rect.handle.handle--e')?.setAttribute('style', 'width:0px');
    // Selection box HTML element
    const timelineSelectionElement: HTMLElement | null = chartElement.querySelector('rect.selection');
    // Selection box should be disappeared
    timelineSelectionElement?.style.setProperty('width', '0');
    // Timeline HTML element
    const timelineElement: HTMLElement | null = chartElement.querySelector('g.brush');
    // Next time that a user clicked on the timeline element, remove all hidden property which are set manually
    timelineElement?.addEventListener('mousedown', (event: MouseEvent) => {
      // Only when left button is clicked
      if (event.button == 0) {
        (<HTMLElement>(<HTMLElement>event.currentTarget).querySelector('rect.selection'))?.style.removeProperty('width');
        (<HTMLElement>(<HTMLElement>event.currentTarget).querySelector('rect.handle.handle--w'))?.style.removeProperty('width');
        (<HTMLElement>(<HTMLElement>event.currentTarget).querySelector('rect.handle.handle--e'))?.style.removeProperty('width');
      }
    });
  }



}
