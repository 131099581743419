import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import * as TermsOfServiceActions from '../_store/termsOfService.actions';
import * as fromTermsOfService from '../_store/termsOfService.reducer';
import { FormHelper } from '../../../../_common';
import { ApiService } from '../../../../shared/_services/api.service';
import { SharedService } from '../../../../shared/_services/shared.service';

@Injectable({ providedIn: 'root' })
export class SupplyTermsOfServiceService {

  constructor(
    private http: HttpClient,
    private fh: FormHelper,
    private store: Store<fromTermsOfService.TermsOfServiceState>,
    private api: ApiService,
    private sharedService: SharedService
  ) {
  }

  fetchTermsOfService() {
    this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceRequestAction());
    this.api.getTermsOfService()
      .subscribe((res) => {
          this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceSuccessAction({termsOfService: res.data}));
        },
        (err: any) => {
          this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceFailureAction(err));
        });
  }

  fetchTermsOfServiceById(id: number | string) {
    this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceByIdRequestAction());
    this.api.getTermsOfServiceById(id)
      .subscribe((res) => {
        this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceByIdSuccessAction({currentTermsOfService: res}));
      }, (err: any) => {
        this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceByIdFailureAction(err));
      });
  }
  fetchLatestTermsOfService() {
    this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceByIdRequestAction());
    this.api.getLatestTermsOfService()
      .subscribe((res) => {
        this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceByIdSuccessAction({currentTermsOfService: res}));
      }, (err: any) => {
        this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceByIdFailureAction(err));
      });
  }

  createTermsOfService(termsOfService: any) {
    this.store.dispatch(new TermsOfServiceActions.CreateTermsOfServiceRequestAction());
    this.api.createTermsOfService(termsOfService)
      .subscribe((res) => {
        this.store.dispatch(new TermsOfServiceActions.CreateTermsOfServiceSuccessAction({currentTermsOfService: res}));
        this.sharedService.onHttpCompletionResolve(true, 'Success',  'Terms of service updated',
          '/supply/terms-of-service', 'terms-of-service-created');
      }, (err: any) => {
        this.store.dispatch(new TermsOfServiceActions.CreateTermsOfServiceFailureAction(err));
      });
  }
}
