import { AfterViewInit, Directive, ElementRef, Input, NgZone, Renderer2 } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Directive({
  selector: '[fixedTableHeader]'
})
export class FixedTableHeaderDirective implements AfterViewInit {

  @Input() isExternal: boolean = false;

  private unsubscribeDirective$ = new Subject<void>();
  private unsubscribeScroll$ = new Subject<void>();
  totalBlockMargins: number = 0;
  observer: IntersectionObserver;

  constructor(
    private elRef: ElementRef,
    private zone: NgZone,
    private renderer: Renderer2,
    ) {
  }


  ngAfterViewInit(): void {
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.initScrollListener();
          } else {
            this.removeTableHeaderStyles();
            this.unsubscribeScrollListener();
          }
        });
      },
      {
        rootMargin: this.getRootMargin(),
      },
    );
    this.observer.observe(this.elRef.nativeElement);
  }


  getRootMargin(): string {
    let elementSelectors = [];
    this.totalBlockMargins = 0;
    if (!this.isExternal) {
      elementSelectors = ['nz-header'];
    }
    elementSelectors.forEach(selector => {
      const el = document.querySelector(selector);
      if (el) {
        this.totalBlockMargins += el.getBoundingClientRect().height;
      }
    });
    return `0px 0px -${window.innerHeight - this.totalBlockMargins}px 0px`;
  }


  initScrollListener() {
    const theadEl: HTMLElement = this.elRef.nativeElement.querySelector('thead');
    this.renderer.setStyle(theadEl, 'position', 'relative');
    this.renderer.setStyle(theadEl, 'z-index', 3);
    // this scroll event is runOutsideAngular to prevent the scroll event to execute the change detection in every minor scroll
    this.unsubscribeScroll$ = new Subject<void>();
    this.zone.runOutsideAngular(() => {
      fromEvent(window, 'scroll').pipe(takeUntil(this.unsubscribeDirective$ || this.unsubscribeScroll$))
        .subscribe((ev) => {
          const rect: DOMRect = this.elRef.nativeElement.getBoundingClientRect();
          if (rect.bottom <= window.innerHeight) {
            return;
          }
          if (rect.y <= this.totalBlockMargins) {
            this.renderer.setStyle(theadEl, 'transform', `translateY(${this.totalBlockMargins - rect.y}px)`);
          }
        });
    });
  }


  removeTableHeaderStyles() {
    const theadEl: HTMLElement = this.elRef.nativeElement.querySelector('thead');
    this.renderer.removeStyle(theadEl, 'position');
    this.renderer.removeStyle(theadEl, 'z-index');
    this.renderer.removeStyle(theadEl, 'transform');
  }


  unsubscribeScrollListener(): void {
    this.unsubscribeScroll$.next();
    this.unsubscribeScroll$.complete();
  }


  unsubscribeDirective(): void {
    this.unsubscribeDirective$.next();
    this.unsubscribeDirective$.complete();
  }


  ngOnDestroy() {
    this.observer.unobserve(this.elRef.nativeElement);
    this.unsubscribeScrollListener();
    this.unsubscribeDirective();
  }
}
