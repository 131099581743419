import { Pipe, PipeTransform } from '@angular/core';
import { AppFilter } from '../_models/models';
import _ from 'lodash';

@Pipe({
  name: 'excludeSelectOption'
})
export class ExcludeSelectOptionPipe implements PipeTransform {

  transform(appFilters: AppFilter[], filterId: string, selectOptionId, condition: boolean): AppFilter[] {
    if (appFilters && filterId && selectOptionId && condition) {
      const appFiltersClone = _.cloneDeep(appFilters);
      const findAppFilter = appFiltersClone.find(a => a.id === filterId);
      findAppFilter.possibleValues = findAppFilter.possibleValues.filter(option => option.id !== selectOptionId);
      return appFiltersClone;
    }
    return appFilters;
  }

}
