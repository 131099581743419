<nz-modal [nzVisible]="isModalVisible"
          (nzOnCancel)="onModalAction('CANCEL')"
          (nzOnOk)="onModalAction('OK')"
          nzCentered
          [nzWidth]="modalWidth">

<ng-container *nzModalContent>
  <ng-content></ng-content>
</ng-container>

  <ng-container *nzModalFooter>
    <ng-container *ngFor="let button of modalButtons">
      <ng-container [ngSwitch]="button.type">
        <button *ngSwitchCase="'CANCEL'" nz-button nzType="primary" class="secondary-button"
                id="modal-cancel-btn"
                (click)="onModalAction('CANCEL')">
          {{button.text}}
        </button>
        <button *ngSwitchCase="'DELETE'" nz-button nzType="primary" class="primary-button red-button"
                id="modal-delete-btn"
                (click)="onModalAction('DELETE')">
          {{button.text}}
        </button>
        <button *ngSwitchCase="'APPROVE'" nz-button nzType="primary" class="primary-button" [ngClass]="button.addClass"
                id="modal-approve-btn"
                (click)="onModalAction('APPROVE')">
          {{button.text}}
        </button>
      </ng-container>
    </ng-container>


  </ng-container>
</nz-modal>
