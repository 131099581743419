import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class EnvConfigService {

  constructor() {
    this.setEnvVariables();
    this.googleTagManagerInit(window, document, 'script', 'dataLayer', environment.googleTagContainerId);
  }

  private setEnvVariables(): void {
    // if running angular locally vs ondemand env, need to set user name ondemandUser
    if (environment.envName === 'ondemand') {
      const hostname = location.hostname.split('-')[0] === 'localhost'
        ? (environment.ondemandUser ? environment.ondemandUser : 'NO-USER')
        : location.hostname.split('-')[0];
      environment.javaApiUrl = environment.javaApiUrl.replace('***USER***', hostname);
      environment.javaInsightsUrl = environment.javaInsightsUrl.replace('***USER***', hostname);
      environment.authServer = environment.authServer.replace('***USER***', hostname);
    }
  }

    // --This function rewriting the original script from GTM site--
    // See the original script in comment below:
    // <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    // })(window,document,'script','dataLayer','GTM-NHDBCX7');</script>
  private googleTagManagerInit(w: Window, d: Document, s: string, l: string, i: string): void {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
    // @ts-ignore
    j.async = true;
    // @ts-ignore
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  }
}
