import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  customEvent(eventName: string, object: {[p: string]: string | number}): void {
    const event = {
      event: eventName,
      ...object
    };
    window['dataLayer'].push(event);
  }
}
