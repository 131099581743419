import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { SharedService } from '../../_services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Common } from '../../_common/common.helper';
import { PortalType } from '../../_models/models';

@Component({
  selector: 'app-signup-info',
  templateUrl: './signup-info.component.html',
  styleUrls: ['./signup-info.component.less']
})
export class SignupInfoComponent implements OnInit {
  @Input() publisherId: number;

  portalType: PortalType;

  signupDetails = [
    {id: 'signupDetailsUsername', displayName: 'User Name', value: ''},
    {id: 'signupDetailsEmail', displayName: 'Email', value: ''},
    {id: 'signupDetailsDate', displayName: 'Signup Date (EST)', value: ''},
    {id: 'signupDetailsTermsOfService', displayName: 'Terms of Service Version', value: null},
  ];

  constructor(
    private api: ApiService,
    private sharedService: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public common: Common,
  ) {
  }

  ngOnInit() {
    this.portalType = this.activatedRoute.snapshot.data.portalType;

    // get signup details
    this.api.getSignupDetails(this.publisherId)
      .subscribe((res) => {
        this.signupDetails[0].value = res.data.firstName ? res.data.firstName + ' ' + res.data.lastName : 'n/a';
        this.signupDetails[1].value = res.data.email ? res.data.email : 'n/a';
        this.signupDetails[2].value = res.data.createdAt ? res.data.createdAt : 'n/a';
        this.signupDetails[3].value =
          res.data.termsOfServiceVersion && res.data.termsOfServiceVersion > 0 ? res.data.termsOfServiceVersion : 'n/a';
      }, () => {
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Signup details fetch failed',
          '', '');
      });
  }

  onClickToNavigate(itemId: string) {
    if (itemId === 'signupDetailsTermsOfService' && this.portalType !== 'portal' && this.signupDetails[3].value !== 'n/a') {
      // navigate to terms of service version
      this.router.navigate(['/supply/terms-of-service', this.signupDetails[3].value, 'view']);
    }
  }

}
