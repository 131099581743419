import { Injectable } from '@angular/core';
import {throwError, timer} from 'rxjs';
import {catchError, finalize, switchMap, tap} from 'rxjs/operators';
import {ApiService} from './api.service';
import {cloneDeep, get} from 'lodash';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {PollingConfig, PollingConfigResponseEvaluatorOperation} from '../_models/models';
import {EventService} from './event.service';

@Injectable({
  providedIn: 'root'
})
export class RequestPollingService {
  private readonly LOCAL_STORAGE_KEY = 'pollingSubscriptions';
  private pollingInterval = 5000; // Polling interval in milliseconds
  private pollingsMap: Map<string, PollingConfig> = new Map();
  private pollingResponseEvaluator: Record<PollingConfigResponseEvaluatorOperation, Function> = {
    EQUALS: (a: any, b: any): boolean => a === b,
    IS_NOT: (a: any, b: any): boolean => a !== b,
  };

  constructor(
    private apiService: ApiService,
    private notificationService: NzNotificationService,
    private eventService: EventService,
  ) {
    this.loadStoredPollings();
  }

  private storePollings(): void {
    const arrayFromMap = cloneDeep(Array.from(this.pollingsMap));
    arrayFromMap.forEach(item => {
      delete item[1].subscription;
    });
    const serialized = JSON.stringify(arrayFromMap);
    localStorage.setItem(this.LOCAL_STORAGE_KEY, serialized);
  }

  private loadStoredPollings(): void {
    const serialized = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (serialized) {
      this.pollingsMap = new Map();
      const parsed: [string, PollingConfig][] = JSON.parse(serialized);
      parsed.forEach(([id, config]) => {
        this.startPolling(config);
      });
    }
  }

  startPolling(config: PollingConfig): void {
    if (this.pollingsMap.has(config.id)) {
      return;
    }
    const interval = config.interval || this.pollingInterval;
    config.subscription =
      timer(interval, interval).pipe(
        switchMap(() => this.apiService[config.apiMethod](...config.args)),
        tap((res: any) => {
          const responseEvaluation = this.evaluateResponse(config, res);
          if (responseEvaluation === 'success') {
            // TODO: use NzNotificationRef returned by notificationService to subscribe to the notification close/interaction events
            this.notificationService.success(
              config.completionNotification.success.title,
              config.completionNotification.success.body,
              {nzDuration: 0}
            );
            this.stopPolling(config.id);
          } else if (responseEvaluation === 'error') {
            this.notificationService.error(
              config.completionNotification.error.title,
              config.completionNotification.error.body,
              {nzDuration: 0}
            );
            this.stopPolling(config.id);
          }
        }),
        catchError(error => {
          console.error('Error fetching data:', error);
          this.stopPolling(config.id);
          return throwError(error);
        }),
      ).subscribe();
    this.pollingsMap.set(config.id, config);
    this.storePollings();
  }

  stopPolling(id: string): void {
    const config: PollingConfig = this.pollingsMap.get(id);
    if (config.completionNotification.notificationIdsToRemove?.length) {
      config.completionNotification.notificationIdsToRemove.forEach((notifId: string) => {
        this.notificationService.remove(notifId);
      });
    }
    const subscription = config.subscription;
    if (subscription) {
      subscription.unsubscribe();
      this.pollingsMap.delete(id);
      this.storePollings();
    }
    if (config.completionEvent) {
      this.eventService.emitEvent(config.completionEvent.name, config.completionEvent.data || null);
    }
  }

  private evaluateResponse(config: PollingConfig, res: any): 'success'|'error' {
    const successParamFromPath = get(res, config.responseEvaluators.success.path);
    if (this.pollingResponseEvaluator[config.responseEvaluators.success.operation](successParamFromPath, config.responseEvaluators.success.compareTo)) {
      return 'success';
    }
    const errorParamFromPath = get(res, config.responseEvaluators.error.path);
    if (this.pollingResponseEvaluator[config.responseEvaluators.error.operation](errorParamFromPath, config.responseEvaluators.error.compareTo)) {
      return 'error';
    }
    return null;
  }

}


