import { Component, EventEmitter, Input, Output } from '@angular/core';
import {AudienceTargetingSegment, SegmentType} from '../../_models/models';
@Component({
  selector: 'app-audience-targeting-segment',
  templateUrl: './audience-targeting-segment.component.html',
  styleUrls: ['./audience-targeting-segment.component.less'],
})
export class AudienceTargetingSegmentComponent {
  @Input() showId: boolean;
  @Input() segment: AudienceTargetingSegment;
  @Input() mode: 'view'|'select' = 'view';
  @Input() status: 'selected'|'disabled' = null;
  @Input() rxDealMode = false;
  @Output() selected: EventEmitter<void> = new EventEmitter();
  @Output() editMediaDomainBySegmentId: EventEmitter<AudienceTargetingSegment> = new EventEmitter();
  @Output() deleteSegment: EventEmitter<void> = new EventEmitter();

  isExpanded = false;
  segmentType = SegmentType;

  toggleExpansion(): void {
    this.isExpanded = !this.isExpanded;
  }

  onSegmentClick(): void {
    if (this.status === 'disabled') {
      return;
    }
    this.selected.emit();
  }

  editMediaDomain(segment: AudienceTargetingSegment): void {
    this.editMediaDomainBySegmentId.emit(segment);
  }
}
