<div class="sectionTitle section-title">
  <fa-icon icon="user-check"></fa-icon>
  Signup Details
</div>
<nz-row nzGutter="16">
  <nz-col nzLg="6" nzMd="12" nzSm="24"
          *ngFor="let item of signupDetails">
    <nz-form-item>
      <nz-form-label>{{item.displayName}}</nz-form-label>
      <nz-form-control class="no-cursor">
        <input nz-input
               [value]="item.id === 'signupDetailsDate' ? (item.value !== 'n/a' ? (item.value | date: 'MM/dd/yyyy h:mm a' : common.getGmtOffset(item.value)) : item.value) : item.value"
               class="disabled-input"
               [ngClass]="{'link-text': item.id === 'signupDetailsTermsOfService' && portalType !== 'portal' && item.value !== 'n/a'}"
               [ngStyle]="item.id === 'signupDetailsTermsOfService' && portalType !== 'portal' && item.value !== 'n/a' && {'pointer-events': 'all'}"
               [id]="item.id"
               (click)="onClickToNavigate(item.id)">
      </nz-form-control>
    </nz-form-item>
  </nz-col>
</nz-row>


