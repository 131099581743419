import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import {
  AppFilter,
  AudienceTargetingGroup,
  AudienceTargetingQuery,
  AudienceTargetingSegment,
  CommonDataModel,
  DomainBundlePayload, PortalType,
  RxSegmentMapPayload
} from '../../_models/models';
import { AudienceTargetingService } from '../../../features/inventory-packages/_services/audience-targeting.service';
import { SharedService } from '../../_services/shared.service';
import { cloneDeep, find, isEmpty, pullAt, reject, some } from 'lodash';

import { Store } from '@ngrx/store';
import * as fromAudienceTargeting from '../../../features/inventory-packages/_store/audience-targeting.reducer';
import * as AudienceTargetingActions from '../../../features/inventory-packages/_store/audience-targeting.actions';
import {
  audienceTargetingModelFiltersConfig,
  segmentsRxModelFiltersConfig
} from '../../../features/inventory-packages/_services/audience-targeting.config';
import { FormHelper } from 'src/app/_common';
import { rxSegmentAddExcludeButton, rxSegmentAddIncludeButton } from '../../../features/exchange-deals/_services/exchange-deals.config';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-audience-targeting',
  templateUrl: './audience-targeting.component.html',
  styleUrls: ['./audience-targeting.component.less'],
  animations: [
    trigger('collapseSegments', [
      transition(':enter', [
        animate('0.3s', keyframes([
          style({height: '0px', opacity: 0, offset: 0}),
          style({height: '*', opacity: 0, offset: 0.5}),
          style({height: '*', opacity: 1, offset: 1}),
        ])),
      ]),
      transition(':leave', [
        animate('0.3s', keyframes([
          style({height: '*', opacity: 1, offset: 0}),
          style({height: '*', opacity: 0, offset: 0.5}),
          style({height: '0px', opacity: 0, offset: 1}),
        ])),
      ]),
    ]),
  ],
})
export class AudienceTargetingComponent implements OnInit, OnDestroy {
  @Output() groupsChanged: EventEmitter<AudienceTargetingGroup[]> = new EventEmitter();
  @Output() segmentMapsChanged: EventEmitter<Map<number, RxSegmentMapPayload>> = new EventEmitter();
  @Output() filterChanged: EventEmitter<{ filters: AppFilter[]; filterId: string; isResetFilters: boolean }> = new EventEmitter();
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  @Output() csvFileChanged: EventEmitter<NzUploadFile[]> = new EventEmitter();
  @Output() initGroupsChange: EventEmitter<boolean> = new EventEmitter();
  @Input() initGroups = false;
  @Input() rxDealMode = false;
  @Input() rxSegmentsMetaData: CommonDataModel<AudienceTargetingSegment[]>;
  @Input() segmentList: AudienceTargetingSegment[] = [];
  mediaMode = false;
  rxSegmentAddIncludeButton = rxSegmentAddIncludeButton;
  rxSegmentAddExcludeButton = rxSegmentAddExcludeButton;
  tempGroup: AudienceTargetingGroup = null;
  portalType: PortalType;
  form: UntypedFormArray = this.fb.array([]);
  EMPTY_GROUP_CONTROLS = this.fb.group({
    id: null,
    name: ['', [Validators.required]],
    include: true,
    segments: [[], [Validators.required]],
    isOpen: true,
  });
  isModalVisible = false;
  groupIdxForModal: number = null;
  segmentIdx = 0;
  isSegmentsLoading$: Observable<boolean>;
  modalFilters$: Observable<AppFilter[]>;
  segmentsToAdd: AudienceTargetingSegment[] = [];
  editMedia: boolean;
  segmentMapsPayload: Map<number, RxSegmentMapPayload> = new Map();
  tempSegmentMap: AudienceTargetingSegment = null;
  domainBundlePayload: DomainBundlePayload;
  domainBundleLength = 0;
  uploadedFile: NzUploadFile[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private fh: FormHelper,
    private audienceTargetingService: AudienceTargetingService,
    private store: Store<fromAudienceTargeting.AudienceTargetingState>,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone
  ) {
  }
  get groups() {
    return this.form ? this.form.value : [];
  }
  @Input() set groups(newGroups: AudienceTargetingGroup[]) {
    if (this.initGroups) {
      this.segmentMapsPayload = new Map();
      this.form = this.fb.array([]);
      this.initGroups = false;
      this.initGroupsChange.emit(false);
    }
    if (newGroups && newGroups.length && this.form.value.length === 0) {
      this.pushGroupsToForm(newGroups);
      this.getTotalMaxPrice(newGroups);
    }
  }
  ngOnInit(): void {
    this.initRouteParams();
    this.initRequests();
  }
  initRouteParams(): void {
    // define location usage and consecutive steps
    this.portalType = this.activatedRoute.snapshot.data.portalType;
    const searchFiler = audienceTargetingModelFiltersConfig.find(appFilter => appFilter.id === 'audienceTargetingModelSearchFilter');
    if (this.portalType === 'portal') {
      searchFiler.placeholder = 'Search by Keyword';
    } else if (this.portalType === 'supply') {
      searchFiler.placeholder = 'Search by Keyword or ID';
    }
  }

  pushGroupsToForm(rawGroups: AudienceTargetingGroup[] = []): void {
    rawGroups.forEach(group => {
      this.form.push(this.getNewGroupForm(group));
    });
  }

  initSubscriptions(): void {
    if (this.rxDealMode) {
      this.modalFilters$ = of(segmentsRxModelFiltersConfig);
    } else {
      this.modalFilters$ = this.store.select(fromAudienceTargeting.getModalFilters).pipe(
        tap(res => {
          if (this.isModalVisible) {
            // ====== sort by - start =====
            const findIndexSelectWithSort = res.findIndex(filter => filter.type === 'SELECT_WITH_SORT');
            if (findIndexSelectWithSort > -1 && res[findIndexSelectWithSort].selectedValues
              && res[findIndexSelectWithSort].selectedValues.length > 0) {
              res = cloneDeep(res);
              const toFilter = this.sharedService.stringArrayToFilterValue([res[findIndexSelectWithSort].sortType]);
              res[findIndexSelectWithSort].selectedValues.push(...toFilter);
            }
            // ====== sort by - end ======
            const queryParams = this.sharedService.getQueryParamsFromAppFilters(res) as unknown as AudienceTargetingQuery;
            this.audienceTargetingService.getAudienceTargetingSegments(queryParams);
          }
        })
      );
      this.modalFilters$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      );
      this.isSegmentsLoading$ = this.store.select(fromAudienceTargeting.getIsLoading);
    }
  }

  updateControl(groupIdx: number, controlName: string, value): void {
    this.form.get(`${groupIdx}.${controlName}`).patchValue(value);
    this.form.updateValueAndValidity({onlySelf: false, emitEvent: true});
    this.emitGroupsChanges();
  }

  onGroupNameChange(groupIdx: number, value: string): void {
    this.updateControl(groupIdx, 'name', value);
    this.checkGroupValidity(groupIdx);
  }

  getNewGroupForm(valuesToPatch?: AudienceTargetingGroup): UntypedFormGroup {
    const newGroupForm = cloneDeep(this.EMPTY_GROUP_CONTROLS);
    newGroupForm.patchValue(valuesToPatch || {});
    return newGroupForm;
  }

  onDomainBundleFileUpdated(uploadedFile: NzUploadFile[]) {
    this.uploadedFile = uploadedFile;
    this.csvFileChanged.emit(this.uploadedFile);
  }

  onDomainBundleFileRemoved() {
    this.uploadedFile = null;
  }

  renameDuplicateGroupName(): void {
    const duplicate = this.form.controls.filter(x => x.value.name.includes(this.tempGroup.name));
    if (duplicate.length > 0) {
      const numberInsideBrackets = new RegExp(/^.*?\([^\d]*(\d+)[^\d]*\).*$/);
      const arrayCopies = duplicate.map(x => {
        const res = x.value.name.match(numberInsideBrackets);
        return res ? Number(res[1]) : 0;
      });
      const copyNumber = Math.max(...arrayCopies) + 1;
      this.tempGroup.name += ` (${copyNumber})`;
    }
  }

  addGroup(type: boolean): number {
    this.tempGroup = {
      id: null,
      name: `${type ? 'Inclusion' : 'Exclusion'} Group`,
      include: type,
      segments: [],
      isOpen: true,
    };
    let newGroupIdx = this.groups.length;
    // making sure that exclude group is always last
    if (this.groups.length && this.groups[this.groups.length - 1].include === false && this.tempGroup.include === true) {
      newGroupIdx--;
    }
    this.tempGroup.name += type ? ` ${newGroupIdx + 1}` : '';
    this.renameDuplicateGroupName();
    return newGroupIdx;
  }

  deleteGroup(idx: number): void {
    if (this.rxDealMode) {
      const dealSegmentGroup: AudienceTargetingGroup = this.form.controls[idx].value;
      dealSegmentGroup.segments.forEach(segment => {
        this.segmentMapsPayload.delete(segment.segmentId);
      });
    }
    this.form.removeAt(idx);
    this.emitGroupsChanges(true);
  }

  deleteSegment(groupIdx: number, segmentIdx: number): void {
    const segmentsControl = this.form.get(`${groupIdx}.segments`);
    const segmentsCloneToModify = cloneDeep(segmentsControl.value);
    pullAt(segmentsCloneToModify, [segmentIdx]);
    segmentsControl.setValue(segmentsCloneToModify);
    this.checkGroupValidity(groupIdx);
    this.emitGroupsChanges(true);
  }

  toggleSegmentSelected(segment: AudienceTargetingSegment): void {
    const propName = this.rxDealMode ? 'segmentId' : 'dmpAudienceId';
    if (find(this.segmentsToAdd, [propName, segment[propName]])) { // If the clicked segment is already selected
      this.segmentsToAdd = reject(this.segmentsToAdd, [propName, segment[propName]]);
    } else { // If the clicked segment is not selected
      this.segmentsToAdd.push(cloneDeep(segment));
    }
  }

  getSegmentStatus(segment: AudienceTargetingSegment): 'selected' | 'disabled' {
    const propName = this.rxDealMode ? 'segmentId' : 'dmpAudienceId';
    if (find(this.segmentsToAdd, [propName, segment[propName]])) {
      return 'selected';
    }
    let alreadySelected = false;
    this.groups.forEach(group => {
      if (find(group.segments, [propName, segment[propName]])) {
        alreadySelected = true;
      }
    });
    if (alreadySelected) {
      return 'disabled';
    }
    return null;
  }

  segmentNameChange(segmentDescription: string): void {
    if (this.editMedia) {
      if (this.segmentMapsPayload.has(this.tempSegmentMap?.segmentId)) {
        this.segmentMapsPayload.get(this.tempSegmentMap.segmentId).updateSegmentDescription(segmentDescription);
      } else {
        const existDescription = this.tempSegmentMap.description;
        const rxSegmentMapPayload = new RxSegmentMapPayload(existDescription);
        this.segmentMapsPayload.set(
          this.tempSegmentMap.segmentId, rxSegmentMapPayload
        );
      }
    }
    this.tempSegmentMap.description = segmentDescription;
  }

  domainBundlePayloadChange(domainBundlePayload: DomainBundlePayload): void {
    this.domainBundlePayload = domainBundlePayload;
    this.domainBundleLength = domainBundlePayload?.addedDomainBundles?.length + domainBundlePayload?.removedDomainBundles?.length;
  }

  domainBundlePayloadUpdated(): void {
    if (this.editMedia) {
      if (this.segmentMapsPayload.has(this.tempSegmentMap?.segmentId)) {
        this.segmentMapsPayload.get(this.tempSegmentMap.segmentId).updateDomainBundlePayload(this.domainBundlePayload);
      } else {
        const rxSegmentMapPayload = new RxSegmentMapPayload(this.tempSegmentMap.segmentName);
        rxSegmentMapPayload.updateDomainBundlePayload(this.domainBundlePayload);
        this.segmentMapsPayload.set(
          this.tempSegmentMap.segmentId, rxSegmentMapPayload
        );
      }
    } else {
      if (this.uploadedFile.length > 0) {
        this.tempSegmentMap.segmentMaps = [this.uploadedFile[0].name];
      } else {
        this.tempSegmentMap.segmentMaps = this.domainBundlePayload?.addedDomainBundles.map(d => d.domainBundle);
      }
      this.segmentsToAdd = [this.tempSegmentMap];
    }
  }

  addMediaDomain(segment: AudienceTargetingSegment = null): void {
    if (segment) {
      this.tempSegmentMap = segment;
    } else {
      this.tempSegmentMap = {
        segmentId: undefined,
        segmentName: '',
        type: 'mediadomain',
        segmentMaps: [],
        sourceSegmentId: undefined
      };
    }
  }

  openModalEditSegmentMedia(segment: AudienceTargetingSegment, groupIdx: number, segIdx: number): void {
    this.segmentIdx = segIdx;
    this.groupIdxForModal = groupIdx;
    this.editMedia = Boolean(segment.segmentId);
    this.mediaMode = true;
    this.addMediaDomain(segment);
    this.isModalVisible = true;
  }

  openModal(groupIdx: number, mediaMode = false, existGroup = false): void {
    this.mediaMode = mediaMode;
    if (mediaMode) {
      this.addMediaDomain();
    }
    if (existGroup) {
      this.segmentIdx = this.form.get(`${groupIdx}.segments`).value.length;
    }
    this.groupIdxForModal = groupIdx;
    this.onFiltersChange(null, true);
    this.isModalVisible = true;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.modalFilters$ || this.initSubscriptions();
  }

  onCloseModal(closeMethod: string): void {
    this.audienceTargetingService.gtmCloseEvent(closeMethod);
    if (closeMethod === 'cancelButton') {
      if (this.segmentMapsPayload.has(this.tempSegmentMap?.segmentId)) {
        this.tempSegmentMap.segmentName = this.segmentMapsPayload.get(this.tempSegmentMap.segmentId).existSegmentDescription;
      }
    }
    this.isModalVisible = false;
    this.tempSegmentMap = null;
    this.groupIdxForModal = null;
    this.segmentIdx = 0;
    this.segmentsToAdd = [];
    this.mediaMode = false;
    this.tempGroup = null;
    this.editMedia = null;
    this.domainBundlePayload = null;
    this.domainBundleLength = 0;
  }

  onFiltersChange(event: { filters: AppFilter[]; filterId: string }, isResetFilters = false): void {
    if (this.rxDealMode) {
      this.filterChanged.emit({...event, isResetFilters});
    } else {
      let newFilters: AppFilter[];
      if (isResetFilters) {
        newFilters = this.sharedService.resetFilterSelectedValues(cloneDeep(audienceTargetingModelFiltersConfig));
      } else {
        newFilters = event.filters;
      }
      this.store.dispatch(new AudienceTargetingActions.UpdateModalFilters({modalFilters: newFilters}));
    }
  }

  onAddSegmentsToGroup(): void {
    if (this.mediaMode) {
      this.domainBundlePayloadUpdated();
    }
    if (this.editMedia) {
      this.segmentMapsChanged.emit(this.segmentMapsPayload);
    } else {
      if (this.tempGroup !== null) {
        this.form.insert(this.groupIdxForModal, this.getNewGroupForm(this.tempGroup));
        this.checkGroupValidity(this.groupIdxForModal);
      }
      const segmentsControl = this.form.get(`${this.groupIdxForModal}.segments`) as UntypedFormArray;
      if (this.mediaMode) {
        const seg: AudienceTargetingSegment[] = cloneDeep(segmentsControl.value);
        seg[this.segmentIdx] = this.segmentsToAdd[0];
        segmentsControl.setValue(seg);
      } else {
        const newSegmentsValue = [...cloneDeep(segmentsControl.value), ...this.segmentsToAdd];
        segmentsControl.setValue(newSegmentsValue);
      }
      this.checkGroupValidity(this.groupIdxForModal);
      this.emitGroupsChanges(true);
    }
    this.toggleGroupCollapse(this.groupIdxForModal, true);
    this.onCloseModal('closeOnSaveButton');
  }

  emitGroupsChanges(andGetTotalPrice?: boolean): void {
    this.form.updateValueAndValidity();
    const clone = cloneDeep(this.groups);
    clone.forEach(group => {
      delete group.isOpen;
    });
    this.groupsChanged.emit(clone);
    if (andGetTotalPrice) {
      this.getTotalMaxPrice(clone);
    }
  }

  hasExclusionGroup(): boolean {
    return some(this.groups, ['include', false]);
  }

  getTotalMaxPrice(groups: AudienceTargetingGroup[]): void {
    if (this.rxDealMode) {
      return;
    }
    const filteredGroups = groups.filter(g => g.segments.length > 0);
    this.audienceTargetingService.getTotalMaxPrice(filteredGroups);
  }

  toggleGroupCollapse(groupIdx: number, toState?): void {
    const isOpen = this.form.get(`${groupIdx}.isOpen`).value;
    if (toState !== undefined && isOpen === toState) {
      return;
    } else {
      this.form.get(`${groupIdx}.isOpen`).setValue(!isOpen);
    }
    this.form.updateValueAndValidity();
  }

  trackByFn(index: number, _item: AudienceTargetingGroup): number {
    return index;
  }

  hasError(groupIdx: number, field: keyof AudienceTargetingGroup): string {
    return this.form.get(`${groupIdx}.${field}`).errors ? 'error' : null;
  }

  checkGroupValidity(groupIdx: number): void {
    this.validateGroupName(groupIdx);
    this.validateGroupSegments(groupIdx);
  }

  validateGroupName(groupIdx: number): void {
    const name = this.form.get(`${groupIdx}.name`).value;
    const errors = {};
    let isUnique = true;
    this.form.controls.forEach((group, i) => {
      if (i !== groupIdx && group.value.name.trim() === name.trim()) {
        isUnique = false;
      }
    });
    if (!isUnique) {
      errors['dupName'] = true;
    }
    if (name.trim().length === 0) {
      errors['required'] = true;
    }
    this.form.get(`${groupIdx}.name`).setErrors(isEmpty(errors) ? null : errors);
    this.form.updateValueAndValidity();
  }

  validateGroupSegments(groupIdx: number): void {
    const segments = this.form.get(`${groupIdx}.segments`).value;
    const errors = {};
    if (segments.length === 0) {
      errors['required'] = true;
    }
    this.form.get(`${groupIdx}.segments`).setErrors(isEmpty(errors) ? null : errors);
    this.form.updateValueAndValidity();
  }

  isFormInvalid(): boolean {
    return this.form.invalid;
  }

  markFormAsDirty(): void {
    this.fh.markFormGroupDirtyAndUpdateValidity(this.form);
    this.form.controls.forEach((groupControl, i) => {
      this.checkGroupValidity(i);
      if (groupControl.invalid) {
        groupControl.get('isOpen').setValue(true);
      }
    });
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.store.dispatch(new AudienceTargetingActions.ClearFromStoreAudienceTargetingAction());
    }, 0);
  }

  indexChanged(pageIndex: number): void {
    this.pageChanged.emit(pageIndex);
  }

  addMediaOrSegmentGroup(actionId: string): void {
    switch (actionId) {
      case 'addInclusionGroup':
        this.openModal(this.addGroup(true));
        break;
      case 'addInclusionGroupMedia':
        this.openModal(this.addGroup(true), true);
        break;
      case 'addExclusionGroup':
        this.openModal(this.addGroup(false));
        break;
      case 'addExclusionGroupMedia':
        this.openModal(this.addGroup(false), true);
        break;
    }
  }

  private initRequests(): void {
    if (!this.rxDealMode) {
      this.audienceTargetingService.getAudienceTargetingProviderList();
      this.audienceTargetingService.getAudienceTargetingSortByList();
    }
  }
}
