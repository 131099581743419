import { AppFilter } from '../../../shared/_models/models';

export const audienceTargetingModelFiltersConfig: AppFilter[] = [
  new AppFilter('audienceTargetingModelSearchFilter', 'SEARCH', '', null, 'Search by Keyword or ID',
    'default', true, true, {nameProp: 'keyword'}, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, 'query',
    undefined, undefined, undefined, undefined, true),
  new AppFilter('audienceTargetingModelSortBy', 'SELECT_WITH_SORT', '', null, 'Sort By',
    'default', false, true, {nameProp: 'sort'}, [], undefined,
    undefined, undefined, undefined, undefined, [{id: 'name', value: 'name', displayName: 'Sort by Name'}],
    undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, 'query',
    undefined, undefined, undefined, undefined, true),
  new AppFilter('audienceTargetingModelSearchCriteria', 'SELECT', '', null, 'Filter by Provider',
    'multiple', true, true, {nameProp: 'provider'},
    [], undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, 'query',
    undefined, undefined, undefined, undefined, true),
];

export const segmentsRxModelFiltersConfig: AppFilter[] = [
  new AppFilter('segmentsRxModelSearchFilter', 'SEARCH', '', null, 'Search by Keyword or DMP ID',
    'default', true, true, {nameProp: 'keyword'}, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, 'query',
    undefined, undefined, undefined, undefined, true),
  new AppFilter('segmentsRxModelSortBy', 'SELECT_WITH_SORT', '', null, 'Sort By',
    'default', false, true, {nameProp: 'sort'}, [
      {id: 'name', value: ['displayName', 'segmentAudience.name'], displayName: 'Sort by Name'},
      {id: 'dmpAudienceId', value: ['segmentAudience.dmpAudienceId'], displayName: 'Sort by DMP ID'},
      {id: 'type', value: ['type'], displayName: 'Sort by Type'}
    ], undefined,
    undefined, undefined, undefined, undefined,
    [{id: 'name', value: ['displayName', 'segmentAudience.name'], displayName: 'Sort by Name'}],
    undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, 'query',
    undefined, undefined, undefined, undefined, true),
  new AppFilter('segmentsRxModelFilterType', 'SELECT', '', null, 'Filter by Type',
    'multiple', true, true, {nameProp: 'provider'},
    [
      {id: 'audience', value: 'audience', displayName: 'Audience' },
      {id: 'comscore', value: 'comscore', displayName: 'Comscore' },
      {id: 'grapeshot', value: 'grapeshot', displayName: 'Grapeshot' },
      {id: 'grapeshot_language', value: 'grapeshot_language', displayName: 'Grapeshot Language' },
      {id: 'contentlanguage', value: 'contentlanguage', displayName: 'Content Language' },
      {id: 'contentrating', value: 'contentrating', displayName: 'Content Rating' },
      {id: 'contentgenre', value: 'contentgenre', displayName: 'Content Genre' },
    ], undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, 'query',
    undefined, undefined, undefined, undefined, true),
];

export const segmentsMock = [
  {
    segmentId: 1,
    dmpAudienceId: 1,
    segmentName: 'seg1',
    description: 'desc1',
    providerName: 'prov1',
    cpm: 1.11,
    segmentType: 'type1',
  },
  {
    segmentId: 2,
    dmpAudienceId: 2,
    segmentName: 'seg2',
    description: 'desc2',
    providerName: 'prov2',
    cpm: 2.22,
    segmentType: 'type2',
  },
  {
    segmentId: 3,
    dmpAudienceId: 3,
    segmentName: 'seg3',
    description: 'desc3',
    providerName: 'prov3',
    cpm: 3.33,
    segmentType: 'type3',
  },
  {
    segmentId: 4,
    dmpAudienceId: 4,
    segmentName: 'seg4',
    description: 'desc4',
    providerName: 'prov4',
    cpm: 4.44,
    segmentType: 'type4',
  },
  {
    segmentId: 5,
    dmpAudienceId: 5,
    segmentName: 'seg5',
    description: 'desc5',
    providerName: 'prov5',
    cpm: 5.55,
    segmentType: 'type5',
  },
];

