import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';

import { AuthenticationService } from '../../auth/_services/authentication.service';

export const nonAuthUrls = [
  'users/email',
  'users/password/forgot',
  'users/password/reset',
  'captcha',
  'signup',
  'termsofservice/latest',
  'location/country/get',
  'location/region/get',
];

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = this.auth.currentUserValue;
    let token;

    if (currentUser?.access_token) {
      token = currentUser.access_token;
    }

    if (token) {
      request = this.setBearerToken(request, token);
    }

      // for java requests - if not login/signup or captcha and no token => abort
    if (request.url.toString().includes('-japi') && !currentUser &&
      !nonAuthUrls.some(url => request.url.toString().includes(url))) {
      return EMPTY;
    }

    return next.handle(request);
  }


  private setBearerToken(request: HttpRequest<any>, token: string): HttpRequest<any>{
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
