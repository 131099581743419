<ng-container *ngIf="groups.length === 0">
  <div nz-row nzJustify="start" nzAlign="left" nzGutter="24">
    <div nz-col nzSpan="12" nzLg="12" nzSm="24" nzXs="24"
         class="display-flex flex-column align-items-baseline margin-top-1 margin-left-1">
      <div class="semibold-font max-width-55ch">
        <ng-container *ngIf="!rxDealMode; else rxDealTitle">
          <div><span>Here you can manage audiences for deal targeting.</span></div>
          <span>For more information please contact your Nexxen account representative.</span>
        </ng-container>
      </div>
      <ng-container *ngTemplateOutlet="addGroupButtonsTpl"></ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="groups.length > 0">
  <ng-container
    *ngFor="let group of groups; let groupIdx = index; let firstGroup = first; let lastGroup = last; trackBy: trackByFn">
    <ng-container *ngIf="lastGroup && !group.include">
      <ng-container *ngTemplateOutlet="addGroupButtonsTpl"></ng-container>
    </ng-container>
    <div class="tree-operand bold-font small-font margin-top-small margin-left-small"
         [ngClass]="!group.include ? 'font-icon-color-exclude-group' : 'font-icon-color-darkest'">
      {{firstGroup ? '' : 'AND'}} {{group.include ? 'INCLUDE' : 'EXCLUDE'}}
    </div>
    <div class="audience-targeting-groups-wrapper margin-top-small">
      <div nz-row nzGutter="16">
        <nz-col nzLg="6" nzMd="8" nzSm="24">
          <div class="display-flex align-items-center">
            <fa-icon icon="chevron-right"
                     [rotate]="form.get(groupIdx+'.isOpen').value ? 90 : null"
                     class="small-font margin-right-small cursor-pointer"
                     (click)="toggleGroupCollapse(groupIdx)"></fa-icon>
            <nz-form-item class="flex-1 segment-group-name-form-item"
                          [ngClass]="form.get(groupIdx+'.isOpen').value ? 'open' : ''">
              <nz-form-control [nzValidateStatus]="hasError(groupIdx, 'name') || hasError(groupIdx, 'segments')" [nzErrorTip]="groupNameErrorTipTpl">
                <input nz-input [ngModel]="form.get(groupIdx+'.name').value"
                       (ngModelChange)="onGroupNameChange(groupIdx, $event)"
                       [id]="'at-group-name-input'+groupIdx"
                       placeholder="Enter a group name (required)"
                       autocomplete="off">
              </nz-form-control>
              <ng-template #groupNameErrorTipTpl>
                <ng-container *ngIf="form.get(groupIdx+'.name').hasError('dupName')">
                  <div>Duplicate group name</div>
                </ng-container>
                <ng-container *ngIf="form.get(groupIdx+'.name').hasError('required')">
                  <div>Group name is required</div>
                </ng-container>
                <ng-container *ngIf="form.get(groupIdx+'.segments').hasError('required')">
                  <div>Group must contain segments</div>
                </ng-container>
              </ng-template>
            </nz-form-item>
          </div>
        </nz-col>
        <nz-col nzLg="18" nzMd="16" nzSm="24">
          <div class="display-flex align-items-center gap-1">
            <div class="flex-1 display-flex justify-content-flex-end margin-right-1">
              <button type="button" nz-button class="secondary-button red-button small-font small-height small-padding"
                      [id]="'at-delete-group-btn-'+groupIdx" (click)="$event.preventDefault()"
                      [nzCondition]="group.segments.length ? false : true"
                      [nzPopconfirmTitle]="deleteGroupPopconfirmTitleTpl"
                      nz-popconfirm nzOkText="Delete" (nzOnConfirm)="deleteGroup(groupIdx)">
                Delete Group
              </button>
              <ng-template #deleteGroupPopconfirmTitleTpl>
                <fa-icon icon="exclamation-circle"
                         class="font-icon-color-warning-mid-dark margin-right-small"></fa-icon>
                Are you sure you want to delete this group?
              </ng-template>
            </div>
          </div>
        </nz-col>
      </div>
      <ng-container>
        <div *ngIf="form.get(groupIdx+'.isOpen').value" class="padding-top-small padding-bottom-small"
             @collapseSegments>
          <div nz-row class="padding-top-1 overflow-y-clip">
            <nz-col nzLg="24" nzMd="24" nzSm="24">
              <ng-container *ngFor="let segment of group.segments; let segmentIdx = index; let lastSegment = last">
                <div class="display-flex align-items-center tree-branch-wrapper">
                  <div class="flex-1 tree-branch">
                    <app-audience-targeting-segment
                      [rxDealMode]="rxDealMode"
                      [showId]="portalType === 'supply'"
                      [segment]="segment"
                      [mode]="'view'"
                      (editMediaDomainBySegmentId)="openModalEditSegmentMedia($event, groupIdx, segmentIdx)"
                      (deleteSegment)="deleteSegment(groupIdx, segmentIdx)"
                    ></app-audience-targeting-segment>
                  </div>
                </div>
                <div *ngIf="!lastSegment" class="tree-operand bold-font smaller-font font-icon-color-ocean">OR</div>
              </ng-container>
              <ng-container *ngIf="group.segments.length === 0">
                <div class="display-flex align-items-center tree-branch-wrapper">
                  <div class="flex-1 tree-branch">
                    <div class="small-font semibold-font"
                         [ngClass]="form.get(groupIdx+'.segments').dirty ? 'font-icon-color-error' : ''">
                      Empty group. Please add segments before saving.
                    </div>
                  </div>
                </div>
              </ng-container>
            </nz-col>
          </div>
        </div>
        <a class="add-segment-btn display-inline-flex align-items-center line-height-1 link-button"
           [id]="'at-add-segments-to-group-btn-'+groupIdx" (click)="openModal(groupIdx)">
          <fa-icon icon="plus-circle" class="fa-md cursor-pointer margin-right-small"></fa-icon>
          Add Segments
        </a>
        <a *ngIf="rxDealMode" class="add-segment-btn display-inline-flex align-items-center line-height-1 link-button"
           [id]="'at-add-media-to-group-btn-'+groupIdx" (click)="openModal(groupIdx, true, true)">
          <fa-icon icon="plus-circle" class="fa-md cursor-pointer margin-right-small"></fa-icon>
          Add Media
        </a>
      </ng-container>
    </div>
    <ng-container *ngIf="lastGroup && group.include">
      <ng-container *ngTemplateOutlet="addGroupButtonsTpl"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #addGroupButtonsTpl>
  <div class="display-flex align-items-center margin-top-2 margin-bottom-2 gap-1">
    <ng-container *ngIf="!rxDealMode; else rxSegmentAddIncludeButtonTemp">
      <button nz-button
              class="primary-button small-padding small-font small-height"
              appGtmIdImplement="addInclusionGroup"
              nzType="primary"
              (click)="openModal(addGroup(true))"
              id="at-open-modal-first-inclusion-group-btn">
        Add Inclusion Group
      </button>
    </ng-container>
    <div nz-tooltip *ngIf="!hasExclusionGroup()"
         [nzTooltipTitle]="hasExclusionGroup() ? 'Package can have only one exclusion audience group' : ''">
      <ng-container *ngIf="!rxDealMode; else rxSegmentAddExcludeButtonTemp">
        <button nz-button
                class="secondary-button margin-left-1 small-padding small-font small-height"
                appGtmIdImplement="addExclusionGroup"
                nzType="secondary" (click)="openModal(addGroup(false))"
                id="at-open-modal-first-exclusion-group-btn">
          Add Exclusion Group
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>

<nz-modal [(nzVisible)]="isModalVisible"
          [nzWidth]="mediaMode ? '70%' : 700"
          (nzOnCancel)="onCloseModal('backgroundOrX')"
          [nzFooter]="modalFooter"
          class="dark-mask">
  <ng-container *nzModalContent>
    <ng-container *ngIf="!mediaMode; else mediaModal">
      <div class="sectionTitle borderless margin-bottom-none">
        {{rxDealMode ? 'Add Content and Data Segments' : 'Add Audience Segments'}}
      </div>
      <div class="small-font light-font margin-bottom-1">
        Select one or more available {{rxDealMode ? 'content or data segments' : 'audience segments'}}.<br>
        <ng-container *ngIf="!rxDealMode">
          Please select both 'HHT' and '3rd Party' segment types to maximize scale.
        </ng-container>
      </div>
      <ng-container *ngIf="(modalFilters$ | async) as modalFilters">
        <app-filters
          [filters]="modalFilters | excludeSelectOption: 'audienceTargetingModelSortBy': 'dmpAudienceId': (portalType === 'portal')"
          [isDataLoading]="(isSegmentsLoading$ | async) || rxSegmentsMetaData?.isLoading"
          (filtersChanged)="onFiltersChange($event)">
        </app-filters>
      </ng-container>

      <ng-container *ngIf="isModalVisible">
        <ng-container *ngIf="!((isSegmentsLoading$ | async) || rxSegmentsMetaData?.isLoading); else segmentsLoadingTpl">
          <div class="segments-list" *ngIf="segmentList?.length; else noSegmentsTpl">
            <cdk-virtual-scroll-viewport class="segment-list-container" [itemSize]="50">
              <ng-container *cdkVirtualFor="let segment of segmentList; let idx = index">
                <div class="app-segment-wrapper margin-bottom-small">
                  <app-audience-targeting-segment
                    [rxDealMode]="rxDealMode"
                    [showId]="portalType === 'supply'"
                    [segment]="segment"
                    [mode]="'select'"
                    [status]="getSegmentStatus(segment)"
                    (selected)="toggleSegmentSelected(segment)"
                  ></app-audience-targeting-segment>
                </div>
              </ng-container>
            </cdk-virtual-scroll-viewport>
          </div>
          <ng-template #noSegmentsTpl>
            <div class="margin-top-2 margin-bottom-2 text-center bold-font">
              No segments found
            </div>
          </ng-template>
        </ng-container>
        <ng-template #segmentsLoadingTpl>
          <div class="margin-top-2 margin-bottom-2">
            <nz-spin nzTip="Loading segments..."></nz-spin>
          </div>
        </ng-template>
        <div *ngIf="rxDealMode && rxSegmentsMetaData?.rxMetaData" class="margin-top-1 display-flex justify-content-center">
          <nz-pagination
            [nzPageIndex]="rxSegmentsMetaData.rxMetaData?.currentPage"
            [nzPageSize]="rxSegmentsMetaData.rxMetaData?.itemsPerPage"
            [nzTotal]="rxSegmentsMetaData.totalElements"
            (nzPageIndexChange)="indexChanged($event)"
          ></nz-pagination>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</nz-modal>

<ng-template #mediaModal>
  <div class="sectionTitle borderless margin-bottom-none">
    {{editMedia ? 'Edit' : 'Add'}}
    <ng-container [ngSwitch]="tempSegmentMap.type">
      <ng-container *ngSwitchCase="'mediaid'">Media: App IDs or Site IDs</ng-container>
      <ng-container *ngSwitchCase="'mediadomain'">Media: App Bundles or Site Domains</ng-container>
      <ng-container *ngSwitchCase="'pubid'">Publishers</ng-container>
    </ng-container>
  </div>
  <div class="small-font light-font margin-bottom-1">
    {{editMedia ? 'Edit' : 'Add'}}
    <ng-container [ngSwitch]="tempSegmentMap.type">
      <ng-container *ngSwitchCase="'mediaid'">
        one or more site IDs or bundle IDs separated by a comma or space. Each ID should be in this format: sspid||mediaid.
      </ng-container>
      <ng-container *ngSwitchCase="'mediadomain'">
        one or more site domains or app bundles separated by a comma or space.
      </ng-container>
      <ng-container *ngSwitchCase="'pubid'">
        one or more CTRL publisher IDs.
      </ng-container>
    </ng-container>
  </div>
  <nz-radio-group *ngIf="!editMedia" [nzDisabled]="!!domainBundleLength" [(ngModel)]="tempSegmentMap.type"
                  class="margin-bottom-1">
    <label nz-radio nzValue="mediadomain">App Bundles or Site Domains</label>
    <label nz-radio nzValue="mediaid">App IDs or Site IDs</label>
    <label nz-radio nzValue="pubid">Publisher IDs</label>
  </nz-radio-group>
  <nz-col>
    <nz-form-item>
      <nz-form-label>Description</nz-form-label>
      <nz-form-control>
        <input nz-input placeholder="Enter description" autocomplete="off"
               [ngModel]="tempSegmentMap.description" (ngModelChange)="segmentNameChange($event)">
      </nz-form-control>
    </nz-form-item>
  </nz-col>
  <app-domain-bundles
    parentType="RX-DEAL"
    [rxSegmentMapPayload]="segmentMapsPayload.get(tempSegmentMap.segmentId)"
    [editMode]="editMedia ? 'EDIT' : 'CREATE'"
    [parent]="tempSegmentMap"
    [groups]="groups"
    (domainBundlePayloadUpdated)="domainBundlePayloadChange($event)"
    [segmentType]="tempSegmentMap.type"
    (domainBundleFileRemoved)="onDomainBundleFileRemoved()"
    (domainBundleFileUpdated)="onDomainBundleFileUpdated($event)"
  >
  </app-domain-bundles>
</ng-template>

<ng-template #rxDealTitle>
  <div>
    Here you can create inclusion groups and/or an exclusion group, and assign different segment types to them: Content (genre, language, rating),
    Data (audience, Comscore, Grapeshot, Grapeshot Language), and/or Media (App Bundle/Site Domain, App ID/Site ID)
  </div>
</ng-template>

<ng-template #modalFooter>
  <button nz-button class="secondary-button" (click)="onCloseModal('cancelButton')"
          appGtmIdImplement="cancelAddAudienceSegment"
          id="at-modal-cancel-btn">Cancel
  </button>
  <button nz-button class="primary-button" nzType="primary" (click)="onAddSegmentsToGroup()"
          [disabled]="segmentsToAdd.length === 0 && domainBundleLength === 0 &&
          !domainBundlePayload?.removedDomainBundles?.length > 0 &&
          !domainBundlePayload?.addedDomainBundles?.length > 0 &&
          !domainBundlePayload?.removeAllDomains && (
           !segmentMapsPayload?.get(tempSegmentMap?.segmentId) || segmentMapsPayload.get(tempSegmentMap.segmentId).isNotChanged()
          ) && uploadedFile.length === 0"
          appGtmIdImplement="addSelected" [gtmValue]="segmentsToAdd.length"
          id="at-modal-add-selected-btn">
    Add{{editMedia ? '/Remove' : ''}} Selected ({{mediaMode ? (domainBundleLength || uploadedFile.length) : segmentsToAdd.length}})
  </button>
</ng-template>

<ng-template #rxSegmentAddIncludeButtonTemp>
  <app-dropdown-button
    [buttons]="rxSegmentAddIncludeButton"
    [isLoading]="(isSegmentsLoading$ | async) || rxSegmentsMetaData?.isLoading"
    (buttonClicked)="addMediaOrSegmentGroup($event)">
  </app-dropdown-button>
</ng-template>
<ng-template #rxSegmentAddExcludeButtonTemp>
  <app-dropdown-button
    [buttons]="rxSegmentAddExcludeButton"
    [isLoading]="(isSegmentsLoading$ | async) || rxSegmentsMetaData?.isLoading"
    (buttonClicked)="addMediaOrSegmentGroup($event)">
  </app-dropdown-button>
</ng-template>
