import {APP_INITIALIZER, importProvidersFrom, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

import { JwtModule } from '@auth0/angular-jwt';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { clearState, reducers } from './app.reducer';
import { EnvConfigService } from './shared/_services/env-config.service';

import { environment } from '../environments/environment.qa';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { ClipboardModule } from 'ngx-clipboard';
import {CurrentUser} from './shared/_models/models';
import {RequestPollingService} from './shared/_services/request-polling.service';
import {RouteReuseStrategy} from '@angular/router';
import {CustomRouteReuseStrategy} from './shared/_strategies/custom-route-reuse.strategy';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ClipboardModule,
    StoreModule.forRoot(reducers, { metaReducers: [clearState] }),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 5}) : [],
    SharedModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [EnvConfigService, RequestPollingService],
      multi: true,
    },
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: () => ((localStorage.getItem('currentUser') as unknown) as CurrentUser)?.access_token,
        }
      })
    ),
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
